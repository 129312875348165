import React from 'react';
import MainFooter from './main-footer';
import MainNavigation from './main-navigation';

const MainLayout: React.FC = ({ children }) => (
  <>
    <MainNavigation />
    <div
      className="mt-24 md:mt-28"
      style={{
        backgroundColor: 'rgb(53, 60, 68)',
        minHeight: 'calc(100vh - 236px - 7rem)',
      }}
    >
      {children}
    </div>
    <MainFooter />
  </>
);

export default MainLayout;
