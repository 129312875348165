import { Link } from 'gatsby';
import React from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import _JSXStyle from 'styled-jsx/style';
import InstagramIcon from './icons/instagram-icon';
import FacebookIcon from './icons/facebook-icon';
import logo from '../img/icons/logo.svg';

const MobileMenu: React.FC = ({ children }) => (
  <div
    style={{ backgroundColor: 'rgb(53, 60, 68)', width: '320px' }}
    className="fixed h-full right-0 top-0 flex flex-col justify-center items-center z-40"
  >
    {children}
  </div>
);

const MainNavigation: React.FC = () => {
  const links = [
    {
      label: '.bim',
      slug: '/bim',
    },
    {
      label: '.o nás',
      slug: '/o-nas',
    },
    {
      label: '.projekty',
      slug: '/projekty',
    },
    {
      label: '.služby',
      slug: '/sluzby',
    },
    {
      label: '.kontakt',
      slug: '/kontakt',
    },
    {
      label: '.faq',
      slug: '/faq',
    },
  ];

  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  React.useEffect(() => {
    // fix for styled-jsx
    console.log(_JSXStyle);
  }, []);

  return (
    <div
      className="fixed z-50 top-0 w-full py-4 md:px-20 px-4 text-white shadow-2xl"
      style={{ backgroundColor: 'rgb(53, 60, 68)' }}
    >
      <div className="container flex justify-between items-center m-auto">
        <Link to="/">
          <img className="h-16 md:h-20" src={logo} alt="logo" />
        </Link>
        <div className="flex items-center navigation-links">
          {links.map((link) => (
            <Link
              key={link.slug}
              className="p-6 text-xl link"
              to={link.slug}
              activeClassName="link--active"
            >
              {link.label}
            </Link>
          ))}
          <div className="flex ml-6 mb-2">
            <a href="https://www.facebook.com" target="blank" className="p-2">
              <FacebookIcon />
            </a>
            <a href="https://www.instagram.com" target="blank" className="p-2">
              <InstagramIcon />
            </a>
          </div>
        </div>
        <div className="mobile-navigation">
          <HamburgerMenu
            className="z-50"
            isOpen={isMobileMenuOpen}
            color="white"
            menuClicked={() => setIsMobileMenuOpen((prevState) => !prevState)}
          />
        </div>
        {isMobileMenuOpen && (
          <MobileMenu>
            {links.map((link) => (
              <Link
                key={link.slug}
                className="py-4 px-6 text-xl link"
                to={link.slug}
                activeClassName="link--active"
              >
                {link.label}
              </Link>
            ))}
            <div className="flex mt-4">
              <a href="https://www.facebook.com" target="blank" className="p-4">
                <FacebookIcon />
              </a>
              <a
                href="https://www.instagram.com"
                target="blank"
                className="p-4"
              >
                <InstagramIcon />
              </a>
            </div>
          </MobileMenu>
        )}
      </div>
      <style jsx>{`
        @media screen and (max-width: 1150px) {
          .navigation-links {
            display: none;
          }
        }

        @media screen and (min-width: 1151px) {
          .mobile-navigation {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default MainNavigation;
