import React from 'react';

const MailIcon: React.FC = () => (
  <svg
    enableBackground="new 0 0 48 48"
    height="48px"
    id="mail"
    version="1.1"
    viewBox="0 0 96 96"
    width="96px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M80,12H16C9.373,12,4,17.373,4,24v48c0,6.627,5.373,12,12,12h64c6.627,0,12-5.373,12-12V24C92,17.373,86.627,12,80,12z   M80,20c0.459,0,0.893,0.093,1.303,0.235L48,46.877L14.697,20.235C15.108,20.093,15.541,20,16,20H80z M80,76H16c-2.21,0-4-1.79-4-4  V28.323l33.501,26.8C46.231,55.708,47.116,56,48,56s1.769-0.292,2.499-0.877L84,28.323V72C84,74.21,82.21,76,80,76z" />
  </svg>
);

export default MailIcon;
