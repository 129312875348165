import React from 'react';
import LocationIcon from './icons/location-icon';
import MailIcon from './icons/mail-icon';
import PhoneIcon from './icons/phone-icon';

const MainFooter: React.FC = () => (
  <div className="w-full">
    <div className="w-full text-white" style={{ backgroundColor: '#0f161d' }}>
      <div className="container m-auto flex flex-col md:flex-row justify-center items-center flex-wrap md:px-20 px-4 py-8">
        <div className="flex flex-col items-center text-center my-4">
          <LocationIcon />
          <div className="mt-2">
            BIM HOUSE
            <br />
            Považská Teplá 572
          </div>
        </div>
        <div className="flex flex-col items-center text-center m-auto my-4">
          <MailIcon />
          <a href="mailto:jaroslav.zelenak@bimhouse.sk" className="mt-2">
            jaroslav.zelenak@bimhouse.sk
          </a>
        </div>
        <div className="flex flex-col items-center text-center my-4">
          <PhoneIcon />
          <a href="tel:+421904035844" className="mt-2">
            +421 904 035 844
          </a>
        </div>
      </div>
    </div>
    <div className="w-full text-center text-white p-2 text-sm bg-black">
      Created by{' '}
      <a href="https://www.linkedin.com/in/martin-pach">Martin Pach</a>
    </div>
  </div>
);

export default MainFooter;
